import { API } from 'aws-amplify'

export const TransferAPI = {
    fetchTransfer: (staffId, startDate, endDate, status) => API.get('transfers', `/list/staff-date/${staffId}/${startDate}/${endDate}/${status}`, {}),
    fetchTransferById: (transferId) => API.get("transfers", `/get/id/${transferId}`, {}),
    removeTransfer: (transferId) => API.put('transfers', `/update/id/${transferId}`, {body: {deleted: true}}),
    updateTransferById: (transferId, requestBody) => API.put('transfers', `/update/id/${transferId}`, {body: requestBody}),
    fetchPendingTransferDetailById: (transferId) => API.get('transfers_pending', `/get-full/id/${transferId}`, {})
}

export const TransferConstants = {
    STATUS_OPTIONS: {
        IN_PROCESS: 'In Process',
        COMPLETED: 'Completed',
        ACKNOWLEDGED: 'Acknowledged',
        FUNDS_RECEIVED: 'Funds Received',
        PROCESSING: 'Processing',
        NOT_PROCEEDING: 'Not Proceeding',
        AWAITING_FUNDS: 'Awaiting Funds'
    }
}

export const TransfersUtil = {
    getCircularColor: (value) => {
        switch (value) {
            case TransferConstants.STATUS_OPTIONS.IN_PROCESS:
            case TransferConstants.STATUS_OPTIONS.COMPLETED:
            case TransferConstants.STATUS_OPTIONS.ACKNOWLEDGED:
            case TransferConstants.STATUS_OPTIONS.FUNDS_RECEIVED:
            case TransferConstants.STATUS_OPTIONS.AWAITING_FUNDS:
            case TransferConstants.STATUS_OPTIONS.PROCESSING:
                return 'success';
            case TransferConstants.STATUS_OPTIONS.NOT_PROCEEDING:
                return 'red';
            default:
                return 'orange';
        }
    },
    getCircularPercentage: (value) => {
        let percentage;
        switch (value) {
            case 'In Process':
                percentage = 30;
                break;
            case 'Completed':
                percentage = 100;
                break;
            case 'Acknowledged':
                percentage = 20;
                break;
            case 'Funds Received':
                percentage = 70;
                break;
            case 'Not Proceeding':
                percentage = 100;
                break;
            case 'Processing':
                percentage = 40;
                break;
            default:
                percentage = 50;
                break;
        }
        return percentage;
    },
    getTagColor: (stsKey) => {
        let status = 'success';
        switch (stsKey) {
            case 'Draft':
                status = 'orange';
                break;
            case 'Submitted':
                status = 'cyan';
                break;
            case 'Acknowledged':
            case 'Processing':
                status = 'success';
                break;
            case 'Awaiting Funds':
            case 'Funds Received':
            case 'On Hold':
                status = 'red';
                break;
            case 'Payment Made':
            case 'Not Proceeding':
                status = 'blue';
                break;
            case 'Completed':
                status = 'blue';
                break;
            default:
                status = 'success';
                break;
        }
        return status;
    }
}