import axios from 'axios';

export const AppUtil = {
  handle_transaction_date_change: (event, option) => {
    let selDate = '';
    if (event.isValid !== undefined) {
      if (event.toDate().getMonth() >= 9) {
        if (option === 'onlyYearMonth') {
          selDate = event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-01';
        }
        // set it as the first day of the month
        else {
          if (event.toDate().getDate() > 9) {
            selDate =
              event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-' + event.toDate().getDate();
          }
          // + 'T00:00.00.000Z';
          else {
            selDate =
              event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-0' + event.toDate().getDate();
          } // + 'T00:00.00.000Z';
        }
      } else if (event.toDate().getMonth() < 9) {
        if (option === 'onlyYearMonth') {
          selDate = event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-01';
        }
        // set it as the first day of the month
        else {
          if (event.toDate().getDate() > 9) {
            selDate =
              event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-' + event.toDate().getDate();
          }
          // + 'T00:00.00.000Z';
          else {
            selDate =
              event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-0' + event.toDate().getDate();
          } // + 'T00:00.00.000Z';
        }
      }
    } else {
      selDate = event;
    }
    return selDate;
    // eslint-disable-next-line react/prop-types
  },
  getFileInfo: async (url) => {
    const response = await axios.get(url);
    const mimeType = response.headers['content-type'];
    if (mimeType.startsWith('image/')) {
      return {fileType: 'image', url: url, type: mimeType};
    } else if (mimeType === 'application/pdf') {
      return {fileType: 'pdf', url: url, type: mimeType};
    } else {
      return {fileType: null, url: url, type: mimeType}
    }
  },
  currencyFormatter: (isoAlpha3, val, symbol = 'symbol') => {
    return new Intl.NumberFormat(CurrencyLocales[isoAlpha3], {
      style: 'currency',
      currency: isoAlpha3,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currencyDisplay: symbol,
      useGrouping: true
    }).format(val)
  }
};

export const ArrayFormatterUtil = {
  uniq: (result, key = null) => {
    let data = [];
    if (result instanceof Object && key == null) {
      data = result;
    } else {
      if (key == null) {
        data = [...new Set(result)];
      } else {
        data = result.reduce((unique, current) => {
          const exists = unique.some(obj => obj[key] === current[key]);
          if (!exists) {
            unique.push(current);
          }
          return unique;
        }, []);
      }
    }
    return data;
  },
};

export const CurrencyLocales = {
  USD: 'en-US', // US Dollar
  EUR: 'de-DE', // Euro
  JPY: 'ja-JP', // Japanese Yen
  GBP: 'en-GB', // British Pound Sterling
  AUD: 'en-AU', // Australian Dollar
  CAD: 'en-CA', // Canadian Dollar
  CHF: 'de-CH', // Swiss Franc
  CNY: 'zh-CN', // Chinese Yuan
  SEK: 'sv-SE', // Swedish Krona
  NZD: 'en-NZ', // New Zealand Dollar
  MXN: 'es-MX', // Mexican Peso
  SGD: 'en-SG', // Singapore Dollar
  HKD: 'zh-HK', // Hong Kong Dollar
  NOK: 'nb-NO', // Norwegian Krone
  KRW: 'ko-KR', // South Korean Won
  TRY: 'tr-TR', // Turkish Lira
  RUB: 'ru-RU', // Russian Ruble
  INR: 'hi-IN', // Indian Rupee
  BRL: 'pt-BR', // Brazilian Real
  ZAR: 'en-ZA', // South African Rand
  AED: 'ar-AE', // United Arab Emirates Dirham
  ARS: 'es-AR', // Argentine Peso
  CLP: 'es-CL', // Chilean Peso
  COP: 'es-CO', // Colombian Peso
  DKK: 'da-DK', // Danish Krone
  EGP: 'ar-EG', // Egyptian Pound
  IDR: 'id-ID', // Indonesian Rupiah
  ILS: 'he-IL', // Israeli New Shekel
  MYR: 'ms-MY', // Malaysian Ringgit
  NGN: 'en-NG', // Nigerian Naira
  PHP: 'en-PH', // Philippine Peso
  PKR: 'ur-PK', // Pakistani Rupee
  PLN: 'pl-PL', // Polish Zloty
  SAR: 'ar-SA', // Saudi Riyal
  THB: 'th-TH', // Thai Baht
  TWD: 'zh-TW', // New Taiwan Dollar
  UAH: 'uk-UA', // Ukrainian Hryvnia
  VND: 'vi-VN'  // Vietnamese Dong
};

