/*
 *  Take out unused imports improvement by Tom #1076
 */

import React, {Fragment} from 'react';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {connect} from 'react-redux';
// import TransferEdit from "./TransferEdit";
import {Button as AntButton} from 'antd';
import {NavLink} from 'react-router-dom';

import dayjs from 'dayjs';

import {
    //fetchClientDetails,
    loadTransferDetailsRequest,
    loadTransferDetailsSuccess
} from '../../redux/actions/transfers';

// import matchSorter from 'match-sorter'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// @material-ui/icons
// import Today from "@material-ui/icons/Today";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import AvTimer from "@material-ui/icons/AvTimer";

import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
// import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// import staffEditStyles from "./StaffEditStyles";
// import PermIdentity from "@material-ui/icons/PermIdentity";
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {
    Badge,
    Button as ButtonAntD,
    Card as AntCard,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Row,
    Table,
    Tag
} from 'antd';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import {API} from 'aws-amplify';
// import { loadStaffMemberDetailsSuccess } from "../../redux/actions/staff";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import CardFooter from "../Card/CardFooter";
import TransferDetailRemittance from './TransferDetail/TransferDetailRemittance';
import TransferDetailPayout from './TransferDetail/TransferDetailPayout';
import TransferDetailSplitPayPayout from './TransferDetail/TransferDetailSplitPayPayout';
import TransferDetailInvoice from './TransferDetail/TransferDetailInvoice';
import {injectIntl} from 'react-intl';

// import TimelineComponent from 'components/Timeline/Timeline.jsx';
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CardTravel from '@material-ui/icons/CardTravel';
// import Build from "@material-ui/icons/Build";
// import Table from 'components/Table/Table.jsx';
import {TransferDetailLadger} from './TransferDetail/TransferDetailLadger';
import HorizontalStepperNew from '../Timeline/HorizontalStepperNew';
import moment from 'moment';
import TransferDocuments from './TransferDetail/TransferDocuments';

const staffEditStyles = {
    selectStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {color: '#999999'},
    updateProfileButton: {float: 'right'},
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px'
    },
    registerButton: {float: 'right'},
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

class TransferEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // register form
            loaded: false,
            is_loading: true,
            buttonLoading: false,
            current_transfer: {},
            current_transfer_client: {},
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            password: '',
            security_entities: [],
            security_entities_forexsport: false,
            security_entities_forexworldwide: false,
            security_entities_forexstudent: false,
            transfer_nickname: '',
            client: '',
            client_id: '',
            transaction_datetime: '',
            beneficiary_name: '',
            client_list: [],
            currencies_list: [],
            currencies_list_priority: [],
            beneficiaries_list: [],
            transfer_status_list: [],
            beneficiary_id: '',
            beneficiary: {},
            currency_from_id: '',
            navpill_active: 0,
            currency_to_id: '',
            amount_from: '',
            amount_to: '',
            client_rate: '',
            settlement_date: '',
            status_fundsReceivedDatetime: '',
            status_ledgerTransferDatetime: '',
            status_fundsPayoutDatetime: '',
            holdingAccountFrom: {},
            holdingAccountTo: {},
            ledgerFrom: null,
            payouts_list: [],
            fee_visible_amount: 0,
            fee_visible_description: '',
            from_balance: false
        };
    }

    componentDidMount = async () => {
        this.getActive();
        if (this.props.transfer_detail_id) {
            this.props.loadTransferDetailsRequest();

            API.get('transfers', `/get/id/${this.props.transfer_detail_id}`)
                .then(transfer_detail => {
                    this.setState({
                        loaded: true,
                        is_loading: false,
                        transfer_status: transfer_detail.status,
                        ...transfer_detail.fullList
                    });
                    this.props.loadTransferDetailsSuccess();
                    return transfer_detail.fullList;
                })
                .then(response => {
                    API.get('clients', `/get/id/${response.client_id}`)
                        .then(response => {
                            // console.log(response);
                            if (!response) {
                                this.setState({
                                    loaded: false,
                                    is_loading: true
                                });
                            }
                            this.setState({current_transfer_client: response});
                            return response.team_id;
                        })
                        .then(team_id => {
                            API.get('teams', `/get/div-entity/${team_id}`)
                                .then(response => {
                                    // console.log(response)
                                    this.setState({
                                        current_transfer_client: {
                                            ...this.state.current_transfer_client,
                                            division_nickname: response.division_nickname,
                                            entity_nickname: response.entity_nickname,
                                            team_nickname: response.team_nickname
                                        }
                                    });
                                })
                                .catch(error => {
                                    console.log(error);
                                    API.get('teams', `/get/div-entity/${team_id}`)
                                        .then(response => {
                                            // console.log(response)
                                            this.setState({
                                                current_transfer_client: {
                                                    ...this.state.current_transfer_client,
                                                    division_nickname: response.division_nickname,
                                                    entity_nickname: response.entity_nickname,
                                                    team_nickname: response.team_nickname
                                                }
                                            });
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                });
                        })
                        .catch(error => {
                            console.log(error);
                            API.get('transfers', `/get/id/${this.props.transfer_detail_id}`)
                                .then(transfer_detail => {
                                    // console.log(this.props.transfer_detail_id)
                                    // console.log(transfer_detail);
                                    this.setState({
                                        loaded: true,
                                        is_loading: false,
                                        transfer_status: transfer_detail.status,
                                        ...transfer_detail.fullList
                                    });
                                    this.props.loadTransferDetailsSuccess();
                                    return transfer_detail.fullList;
                                })
                                .then(response => {
                                    API.get('clients', `/get/id/${response.client_id}`)
                                        .then(response => {
                                            // console.log(response);
                                            if (!response) {
                                                this.setState({
                                                    loaded: false,
                                                    is_loading: true
                                                });
                                            }
                                            this.setState({current_transfer_client: response});
                                            return response.team_id;
                                        })
                                        .then(team_id => {
                                            API.get('teams', `/get/div-entity/${team_id}`)
                                                .then(response => {
                                                    // console.log(response);
                                                    this.setState({
                                                        current_transfer_client: {
                                                            ...this.state.current_transfer_client,
                                                            division_nickname: response.division_nickname,
                                                            entity_nickname: response.entity_nickname,
                                                            team_nickname: response.team_nickname
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                    API.get('teams', `/get/div-entity/${team_id}`)
                                                        .then(response => {
                                                            // console.log(response);
                                                            this.setState({
                                                                current_transfer_client: {
                                                                    ...this.state.current_transfer_client,
                                                                    division_nickname: response.division_nickname,
                                                                    entity_nickname: response.entity_nickname,
                                                                    team_nickname: response.team_nickname
                                                                }
                                                            });
                                                        })
                                                        .catch(error => {
                                                            console.log(error);
                                                        });
                                                });
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                });
                        });

                    API.get('transfers', `/get-full/id/${this.props.transfer_detail_id}`)
                        .then(response => {
                            console.log(response);
                            let payoutTotal = 0;
                            for (let i = 0; i < response.payouts.length; i++) {
                                payoutTotal += parseFloat(response.payouts[i].amount_to);
                            }

                            let payoutDifference = 0;
                            payoutTotal = Number.parseFloat(payoutTotal || 0).toFixed(2);
                            let transferAmountTo = Number.parseFloat(response.fullList.amount_to || 0);
                            payoutDifference = payoutTotal - transferAmountTo;

                            this.setState({
                                transfer: response.fullList,
                                payouts_list: response.payouts,
                                documents: response.documents,
                                payoutTotal: payoutTotal,
                                payoutDifference: payoutDifference,
                                from_balance:
                                    response.fullList.from_balance == null
                                        ? false
                                        : response.fullList.from_balance === 1
                            });
                        })
                        .catch(error => {
                            console.log(error);
                        });

                    API.get(
                        'holding_accounts',
                        `/get/client-currency/${response.client_id}/${response.currency_from_id}`
                    )
                        .then(response => {
                            console.log(response);
                            this.setState({holdingAccountFrom: response.holding_account_list[0]});
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    API.get('holding_accounts', `/get/client-currency/${response.client_id}/${response.currency_to_id}`)
                        .then(response => {
                            console.log(response);
                            this.setState({holdingAccountTo: response.holding_account_list[0]});
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                    API.get('transfers', `/get/id/${this.props.transfer_detail_id}`)
                        .then(transfer_detail => {
                            // console.log(this.props.transfer_detail_id);
                            // console.log(transfer_detail);
                            this.setState({
                                loaded: true,
                                is_loading: false,
                                transfer_status: transfer_detail.status,
                                ...transfer_detail.fullList
                            });
                            this.props.loadTransferDetailsSuccess();
                            return transfer_detail.fullList;
                        })
                        .then(response => {
                            if (!response) {
                                this.setState({
                                    loaded: false,
                                    is_loading: true
                                });
                                return;
                            }
                            API.get('clients', `/get/id/${response.client_id}`)
                                .then(response => {
                                    // console.log(response);
                                    if (!response) {
                                        this.setState({
                                            loaded: false,
                                            is_loading: true
                                        });
                                    }
                                    this.setState({current_transfer_client: response});
                                    return response.team_id;
                                })
                                .then(team_id => {
                                    API.get('teams', `/get/div-entity/${team_id}`)
                                        .then(response => {
                                            // console.log(response)
                                            this.setState({
                                                current_transfer_client: {
                                                    ...this.state.current_transfer_client,
                                                    division_nickname: response.division_nickname,
                                                    entity_nickname: response.entity_nickname,
                                                    team_nickname: response.team_nickname
                                                }
                                            });
                                        })
                                        .catch(error => {
                                            console.log(error);
                                            API.get('teams', `/get/div-entity/${team_id}`)
                                                .then(response => {
                                                    // console.log(response)
                                                    this.setState({
                                                        current_transfer_client: {
                                                            ...this.state.current_transfer_client,
                                                            division_nickname: response.division_nickname,
                                                            entity_nickname: response.entity_nickname,
                                                            team_nickname: response.team_nickname
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        });
                                });
                        });
                });

            API.get('clients', '/get-list-not-deleted')
                .then(response => {
                    // console.log(response);
                    this.setState({client_list: response});
                })
                .catch(error => {
                    console.log(error);
                });

            API.get('beneficiaries', '/get-all').then(response => {
                // console.log(response);
                this.setState({beneficiaries_list: response});
                return response;
            });

            API.get('transfers', '/get/transfer-status')
                .then(response => {
                    // console.log(response);
                    this.setState({transfer_status_list: response});
                    return response;
                })
                .catch(error => {
                    console.log(error);
                    API.get('transfers', '/get/transfer-status').then(response => {
                        // console.log(response);
                        this.setState({transfer_status_list: response});
                    });
                });

            API.get('currencies', '/list_not_deleted').then(response => {
                this.setState({
                    // currencies_list: response.fullList,
                    currencies_list_priority: response
                });
                return response;
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.transfer_detail_id !== this.props.transfer_detail_id) {
            this.props.loadTransferDetailsRequest();

            API.get('transfers', `/get/id/${this.props.transfer_detail_id}`)
                .then(transfer_detail => {
                    this.setState({
                        loaded: true,
                        is_loading: false,
                        transfer_status: transfer_detail.status,
                        ...transfer_detail.fullList
                    });
                    this.props.loadTransferDetailsSuccess();
                    return transfer_detail;
                })
                .then(response => {
                    API.get('clients', `/get/id/${response.client_id}`)
                        .then(response => {
                            this.setState({current_transfer_client: response});
                        })
                        .catch(error => {
                            console.log(error);
                        });

                    API.get(
                        'holding_accounts',
                        `/get/client-currency/${response.client_id}/${response.currency_from_id}`
                    )
                        .then(response => {
                            this.setState({holdingAccountFrom: response.holding_account_list[0]});
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    API.get('holding_accounts', `/get/client-currency/${response.client_id}/${response.currency_to_id}`)
                        .then(response => {
                            this.setState({holdingAccountTo: response.holding_account_list[0]});
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                    API.get('transfers', `/get/id/${this.props.transfer_detail_id}`)
                        .then(transfer_detail => {
                            console.log(transfer_detail);
                            this.setState({
                                loaded: true,
                                is_loading: false,
                                transfer_status: transfer_detail.status,
                                ...transfer_detail.fullList
                            });
                            this.props.loadTransferDetailsSuccess();
                            return transfer_detail;
                        })
                        .then(response => {
                            API.get('clients', `/get/id/${response.client_id}`)
                                .then(response => {
                                    // console.log(response);
                                    this.setState({current_transfer_client: response});
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        });
                });

            API.get('clients', '/get-list-not-deleted')
                .then(response => {
                    this.setState({client_list: response});
                })
                .catch(error => {
                    console.log(error);
                });

            API.get('beneficiaries', '/get-all').then(response => {
                this.setState({beneficiaries_list: response});
                return response;
            });

            API.get('transfers', '/get/transfer-status')
                .then(response => {
                    this.setState({transfer_status_list: response});
                    return response;
                })
                .catch(error => {
                    console.log(error);
                    API.get('transfers', '/get/transfer-status').then(response => {
                        this.setState({transfer_status_list: response});
                    });
                });

            API.get('currencies', '/list_not_deleted').then(response => {
                this.setState({
                    // currencies_list: response.fullList,
                    currencies_list_priority: response
                });
                return response;
            });
        }
    }

    toTitleCase = str => {
        if (str) {
            return str.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }
    };

    async saveTransferDetail(transfer_detail) {
        return API.put('transfers', `/update/id/${this.props.transfer_detail_id}`, {body: transfer_detail});
    }

    handleChange = event => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleBlur = event => {
        this.setState({[event.target.id]: event.target.value});
    };

    // handleToggle = name => event => {
    //   this.setState({[name]: event.target.checked});
    // };

    toggleEdit = () => {
        const edit_mode = !this.state.edit_mode;
        this.setState({edit_mode: edit_mode});
    };

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({isLoading: true});
        if (
            [this.state.amount_from, this.state.amount_to, this.state.client_rate, this.state.fee_visible_amount].some(
                item => !/^[0-9.]+$/.test(item)
            )
        ) {
            message.info('Numeric values should not have alphabets');
            return;
        }
        // console.log(this.state)
        try {
            await this.saveTransferDetail({
                nickname: this.state.nickname,
                client_id: this.state.client_id,
                transaction_datetime: this.state.transaction_datetime,
                beneficiary_id: this.state.beneficiary_id,
                currency_from_id: this.state.currency_from_id,
                currency_to_id: this.state.currency_to_id,
                from_balance: this.state.from_balance,
                // amount_from: this.state.amount_from.replace(/,/g, ''),
                // amount_to: this.state.amount_to.replace(/,/g, ''),
                amount_from: this.state.amount_from,
                amount_to: this.state.amount_to,
                client_rate: this.state.client_rate,
                settlement_date: this.state.settlement_date,
                status: this.state.status,
                payout_bank_reference: this.state.payout_bank_reference,
                payout_datetime: this.state.payout_datetime,
                record_created_datetime: new Date().toISOString().slice(0, 19).replace('T', ' '),
                staff_id: this.props.app_state.current_staff.id,
                infoRequired: this.state.infoRequired,
                infoRequiredNotes: this.state.infoRequiredNotes,
                fee_visible_amount: this.state.fee_visible_amount,
                fee_visible_description: this.state.fee_visible_description
            });
        } catch (e) {
            alert(e);
            // this.setState({ isLoading: false });
        }
    };

    handleSubmit2 = async event => {
        if (
            [this.state.amount_from, this.state.amount_to, this.state.client_rate, this.state.fee_visible_amount].some(
                item => !/^[0-9.]+$/.test(item)
            )
        ) {
            message.info('Numeric values should not have alphabets');
            return;
        }
        this.setState({buttonLoading: true});
        try {
            await this.saveTransferDetail({
                nickname: this.state.nickname,
                client_id: this.state.client_id,
                transaction_datetime: this.state.transaction_datetime,
                beneficiary_id: this.state.beneficiary_id,
                currency_from_id: this.state.currency_from_id,
                currency_to_id: this.state.currency_to_id,
                // amount_from: this.state.amount_from.replace(/,/g, ''),
                // amount_to: this.state.amount_to.replace(/,/g, ''),
                amount_from: this.state.amount_from,
                amount_to: this.state.amount_to,
                client_rate: this.state.client_rate,
                settlement_date: this.state.settlement_date,
                status: this.state.status,
                payout_bank_reference: this.state.payout_bank_reference,
                payout_datetime: this.state.payout_datetime,
                record_created_datetime: new Date().toISOString().slice(0, 19).replace('T', ' '),
                staff_id: this.props.app_state.current_staff.id,
                infoRequired: this.state.infoRequired,
                infoRequiredNotes: this.state.infoRequiredNotes,
                fee_visible_amount: this.state.fee_visible_amount,
                fee_visible_description: this.state.fee_visible_description
            });
            this.setState({buttonLoading: false});
        } catch (e) {
            alert(e);
            this.setState({buttonLoading: false});
        }
    };

    handleSelectChange = event => {
        this.setState({[event.target.name]: event.target.value});

        if (event.target.name === 'client_id') {
            this.getClientDetails(event.target.value);
        }
        if (event.target.name === 'beneficiary_id') {
            this.getBeneficiaryDetails(event.target.value);
        }
        if (event.target.name === 'currency_base') {
            this.setCurrencyBaseDetails(event.target.value);
        }
        if (event.target.name === 'currency_terms') {
            this.setCurrencyTermsDetails(event.target.value);
        }
    };

    getBeneficiaryDetails(beneficiary_id) {
        API.get('beneficiaries', `/get/id/${beneficiary_id}`)
            .then(response => {
                //console.log(response.id);
                this.setState({
                    beneficiary_id: response.id,
                    beneficiary_name: response.nickname
                });
                return response;
            })
            .then(response => {
                API.get('system', `/currencies/get/${response.account_currency}`)
                    .then(response => {
                        // console.log(response);
                        this.setState({
                            beneficiary: {
                                ...this.state.beneficiary,
                                account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
                            }
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    }

    getClientDetails(client_id) {
        API.get('clients', `/get/id/${client_id}`)
            .then(response => {
                // console.log(response);
                this.setState({client: response});
                return response.team_id;
            })
            .then(team_id => {
                // console.log(team_id);
                API.get('teams', `/get/div-entity/${team_id}`)
                    .then(response => {
                        // console.log(response);
                        this.setState({
                            client: {
                                ...this.state.client,
                                division_nickname: response.division_nickname,
                                entity_nickname: response.entity_nickname,
                                team_nickname: response.team_nickname
                            }
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        API.get('teams', `/get/div-entity/${team_id}`)
                            .then(response => {
                                // console.log(response);
                                this.setState({
                                    client: {
                                        ...this.state.client,
                                        division_nickname: response.division_nickname,
                                        entity_nickname: response.entity_nickname,
                                        team_nickname: response.team_nickname
                                    }
                                });
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    });
            })
            .catch(error => {
                console.log(error);
            });
    }

    setCurrencyBaseDetails(currency_id) {
        API.get('system', '/currencies/get-all')
            .then(response => {
                this.setState({currencies_list: response});
            })
            .catch(error => {
                console.log(error);
            });

        API.get('system', `/currencies/get/${currency_id}`)
            .then(response => {
                this.setState({currency_from_id: response.id});
            })
            .catch(error => {
                console.log(error);
            });
    }

    setCurrencyTermsDetails(currency_id) {
        API.get('system', '/currencies/get-all')
            .then(response => {
                this.setState({currencies_list: response});
            })
            .catch(error => {
                console.log(error);
            });

        API.get('system', `/currencies/get/${currency_id}`)
            .then(response => {
                this.setState({currency_to_id: response.id});
            })
            .catch(error => {
                console.log(error);
            });
    }

    getFullDate = s => {
        //console.log(s);
        let showDate = '';
        if (s.getMonth() >= 9) {
            if (s.getDate() > 9) {
                showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-' + s.getDate();
            } else {
                showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-0' + s.getDate();
            }
        } else if (s.getMonth() < 9) {
            if (s.getDate() > 9) {
                showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-' + s.getDate();
            } else {
                showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-0' + s.getDate();
            }
        }
        return showDate;
    };

    getFullDateTime = s => {
        //console.log(s);
        let showDateTime = '';
        if (s.getMonth() >= 9) {
            if (s.getDate() > 9) {
                showDateTime =
                    s.getFullYear() +
                    '-' +
                    (s.getMonth() + 1) +
                    '-' +
                    s.getDate() +
                    ' ' +
                    s.getHours() +
                    ':' +
                    s.getMinutes() +
                    ':' +
                    s.getSeconds();
            } else {
                showDateTime =
                    s.getFullYear() +
                    '-' +
                    (s.getMonth() + 1) +
                    '-0' +
                    s.getDate() +
                    ' ' +
                    s.getHours() +
                    ':' +
                    s.getMinutes() +
                    ':' +
                    s.getSeconds();
            }
        } else if (s.getMonth() < 9) {
            if (s.getDate() > 9) {
                showDateTime =
                    s.getFullYear() +
                    '-0' +
                    (s.getMonth() + 1) +
                    '-' +
                    s.getDate() +
                    ' ' +
                    s.getHours() +
                    ':' +
                    s.getMinutes() +
                    ':' +
                    s.getSeconds();
            } else {
                showDateTime =
                    s.getFullYear() +
                    '-0' +
                    (s.getMonth() + 1) +
                    '-0' +
                    s.getDate() +
                    ' ' +
                    s.getHours() +
                    ':' +
                    s.getMinutes() +
                    ':' +
                    s.getSeconds();
            }
        }
        return showDateTime;
    };

    getPageHeader(classes) {
        console.log(this.state);
        if (this.state.payouts_list.length == 0) {
            return;
        }

        let currency_from_name = '';
        let currency_to_name = '';
        for (const i in this.state.currencies_list_priority) {
            if (this.state.currencies_list_priority[i].id === this.state.currency_from_id) {
                currency_from_name = this.state.currencies_list_priority[i].short_name;
            }
            if (this.state.currencies_list_priority[i].id === this.state.currency_to_id) {
                currency_to_name = this.state.currencies_list_priority[i].short_name;
            }
        }

        let bankRate = this.state.bank_rate;
        let transferProfit = 0;
        if (this.props.app_state.current_staff.security_seeProfit) {
            if (bankRate) {
                const amountFrom = this.state.amount_from;
                const amountTo = this.state.amount_to;
                const clientRate = this.state.client_rate;
                transferProfit = (amountFrom * clientRate - amountFrom * bankRate) / bankRate;
                transferProfit = Math.abs(transferProfit);
            } else {
                bankRate = 'N/A';
            }
        }

        const items = [
            {
                key: '1',
                label: 'ID',
                children: this.state.id
            },
            {
                key: '2',
                label: 'Date',
                children: moment(this.state.transaction_datetime).format('DD/MM/YYYY')
            },
            {
                key: '3',
                label: 'Settlement',
                children: moment(this.state.settlement_date).format('DD/MM/YYYY')
            },
            {
                key: '4',
                label: 'Beneficiary',
                children: this.state.isMultiPay ? (
                    <p style={{fontSize: '0.8rem'}}>
                        <NavLink to={`/transfers/edit/${this.state.id}?tab=payout`}>MultiPay</NavLink>
                        <br />
                        <em>Select 'Payout' below</em>
                    </p>
                ) : (
                    <p style={{fontSize: '0.8rem'}}>
                        <NavLink to={`/beneficiaries/edit/${this.state.payouts_list[0].beneficiary_id}`}>
                            <strong>{this.state.payouts_list[0].ben_legal_name}</strong>
                        </NavLink>
                        <br />
                        {this.state.payouts_list[0].ben_address_line_1} {this.state.payouts_list[0].ben_address_line_2}
                        <br />
                        {this.state.payouts_list[0].ben_address_suburb} {this.state.payouts_list[0].ben_address_state}{' '}
                        {this.state.payouts_list[0].ben_address_postcode}{' '}
                        {this.state.payouts_list[0].ben_address_countryname}
                        <br />
                        Risk: <Tag color='success'>LOW</Tag>
                        <br />
                        Documents:{' '}
                        {this.state.documents ? (
                            <AntButton
                                type={'link'}
                                size={'small'}
                                onClick={() => {
                                    this.props.history.push(
                                        `/beneficiaries/edit/${this.state.payouts_list[0].beneficiary_id}?tab=5`
                                    );
                                }}
                            >
                                Click Here
                            </AntButton>
                        ) : (
                            '(No Documents Uploaded)'
                        )}
                    </p>
                )
            },
            {
                key: '5',
                label: 'Bank Details',
                span: 2,
                children: this.state.isMultiPay ? (
                    <p style={{fontSize: '0.8rem'}}>
                        <NavLink to={`/transfers/edit/${this.state.id}?tab=payout`}>MultiPay</NavLink>
                    </p>
                ) : (
                    <p style={{fontSize: '0.8rem'}}>
                        <strong>
                            {this.state.payouts_list[0].bank_legal_name}
                            <br />
                        </strong>
                        {this.state.payouts_list[0].bank_address_line_1}
                        <br />
                        {this.state.payouts_list[0].bank_address_line_2}
                        <br />
                        {this.state.payouts_list[0].bank_address_suburb} {this.state.payouts_list[0].bank_address_state}{' '}
                        {this.state.payouts_list[0].bank_address_postcode}{' '}
                        {this.state.payouts_list[0].bank_address_countryname}
                        <br />
                        {this.state.payouts_list[0].swift_code && this.state.payouts_list[0].swift_code !== '' ? (
                            <>
                                SWIFT: {this.state.payouts_list[0].swift_code}
                                <br />
                            </>
                        ) : (
                            ''
                        )}
                        {this.state.payouts_list[0].bsb_code && this.state.payouts_list[0].bsb_code !== '' ? (
                            <>
                                BSB: {this.state.payouts_list[0].bsb_code}
                                <br />
                            </>
                        ) : (
                            ''
                        )}
                        {this.state.payouts_list[0].account_number &&
                        this.state.payouts_list[0].account_number !== '' ? (
                            <>
                                Account: {this.state.payouts_list[0].account_number}
                                <br />
                            </>
                        ) : (
                            ''
                        )}
                        {this.state.payouts_list[0].iban && this.state.payouts_list[0].iban !== '' ? (
                            <>
                                IBAN: {this.state.payouts_list[0].iban}
                                <br />
                            </>
                        ) : (
                            ''
                        )}
                    </p>
                )
            },
            {
                key: '6',
                label: 'From',
                span: 3,
                children: (
                    <p style={{fontSize: '0.8rem'}}>
                        <span
                            className={`currency-flag currency-flag-${
                                currency_from_name ? currency_from_name.toLowerCase() : ''
                            }`}
                        ></span>{' '}
                        {currency_from_name}
                        <br />
                        {this.props.intl.formatNumber(this.state.amount_from, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                    </p>
                )
            },
            {
                key: '7',
                label: 'Rate',
                children: (
                    <p style={{fontSize: '0.8rem'}}>
                        {this.props.intl.formatNumber(this.state.client_rate, {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4
                        })}
                    </p>
                )
            },
            {
                key: '8',
                label: 'To',
                children: (
                    <p style={{fontSize: '0.8rem'}}>
                        <span
                            className={`currency-flag currency-flag-${
                                currency_to_name ? currency_to_name.toLowerCase() : ''
                            }`}
                        ></span>{' '}
                        {currency_to_name}
                        <br />
                        {this.props.intl.formatNumber(this.state.amount_to, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                    </p>
                )
            }
            // {
            //   key: '9',
            //   label: 'Profit',
            //   children: <span>
            //     {currency_from_name} <br/>
            //     {this.props.intl.formatNumber(transferProfit, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            //     </span>,
            // },
            // {
            //     key: '9',
            //     label: 'Mode',
            //     children: (
            //         <div>
            //             <FormControlLabel
            //                 control={
            //                     <Switch
            //                         checked={this.state.edit_mode}
            //                         disabled={!this.props.app_state.current_staff.backofficeStaff}
            //                         onChange={() => this.toggleEdit()}
            //                         value='edit_mode'
            //                         classes={{
            //                             switchBase: classes.switchBase,
            //                             checked: classes.switchChecked
            //                             // icon: classes.switchIcon,
            //                             // iconChecked: classes.switchIconChecked,
            //                             // bar: classes.switchBar
            //                         }}
            //                     />
            //                 }
            //                 classes={{label: classes.label}}
            //                 label={this.state.edit_mode ? 'Edit' : 'View'}
            //             />
            //         </div>
            //     )
            // }
        ];

        return (
            // `${this.state.nickname} [${this.state.current_transfer_client.nickname}]`
            <>
                <Descriptions
                    title={
                        <h4>
                            <strong>{this.state.current_transfer_client.nickname}</strong>
                        </h4>
                    }
                    layout='vertical'
                    size='small'
                    bordered
                    column={14}
                    style={{verticalAlign: 'top !important'}}
                    items={items}
                />
                <Row>
                    <Col xs={20}>
                        <HorizontalStepperNew transfer_status={this.state.status} />
                    </Col>
                    <Col xs={2}></Col>
                    <Col xs={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.edit_mode}
                                    // disabled={!this.props.app_state.current_staff.backofficeStaff}
                                    onChange={() => this.toggleEdit()}
                                    value='edit_mode'
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked
                                        // icon: classes.switchIcon,
                                        // iconChecked: classes.switchIconChecked,
                                        // bar: classes.switchBar
                                    }}
                                />
                            }
                            classes={{label: classes.label}}
                            label={this.state.edit_mode ? 'Edit' : 'View'}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    handleToggle(stateName) {
        let current_stateName = this.state[stateName];
        this.setState({
            [stateName]: current_stateName === 1 ? 0 : 1
        });
    }

    getNavPill_Summary() {
        //set this.props into classes to use
        const {classes} = this.props;

        if (!this.state.current_transfer_client) {
            return null;
        }

        let currency_from_name = '';
        let currency_to_name = '';
        for (const i in this.state.currencies_list_priority) {
            if (this.state.currencies_list_priority[i].id === this.state.currency_from_id) {
                currency_from_name = this.state.currencies_list_priority[i].short_name;
            }
            if (this.state.currencies_list_priority[i].id === this.state.currency_to_id) {
                currency_to_name = this.state.currencies_list_priority[i].short_name;
            }
        }

        const clientDetails = [
            {
                key: '1',
                label: 'Client ID',
                children: (
                    <span>
                        <NavLink to={`/clients/edit/${this.state.current_transfer_client.id}`}>
                            {this.state.current_transfer_client.id}
                        </NavLink>
                    </span>
                )
            },
            {
                key: '1',
                label: 'Team',
                children: <span>{this.state.current_transfer_client.team_nickname}</span>
            },
            {
                key: '1',
                label: 'Individual',
                children: (
                    <span>
                        <NavLink to={`/clients/edit/${this.state.current_transfer_client.id}`}>
                            {this.state.current_transfer_client.first_name}{' '}
                            {this.state.current_transfer_client.last_name}
                        </NavLink>
                    </span>
                )
            },
            {
                key: '2',
                label: 'Company',
                children: (
                    <span>
                        <NavLink to={`/clients/edit/${this.state.current_transfer_client.id}`}>
                            {this.state.current_transfer_client.business_company_name}
                        </NavLink>
                        <br />
                        Co Num: {this.state.current_transfer_client.business_company_number}
                        <br />
                        ABN: {this.state.current_transfer_client.business_abn}
                    </span>
                )
            },
            {
                key: '3',
                label: 'Email',
                children: this.state.current_transfer_client.email
            },
            {
                key: '4',
                label: 'Telephone',
                children: this.state.current_transfer_client.telephone_mobile
            }
        ];

        let bankRate = this.state.bank_rate;
        let transferProfit = 0;
        if (this.props.app_state.current_staff.security_seeProfit) {
            if (bankRate) {
                const amountFrom = this.state.amount_from;
                const amountTo = this.state.amount_to;
                const clientRate = this.state.client_rate;
                transferProfit = (amountFrom * clientRate - amountFrom * bankRate) / bankRate;
                transferProfit = Math.abs(transferProfit);
            } else {
                bankRate = 'N/A';
            }
        }

        const transferDetails = [
            {
                key: '1',
                label: 'Transfer ID',
                children: <span>{this.state.id}</span>
            },
            {
                key: '1',
                label: 'Transaction Date',
                children: <span>{moment(this.state.transaction_datetime).format('DD/MM/YYYY')}</span>
            },
            {
                key: '1',
                label: 'Settlement Date',
                children: <span>{moment(this.state.settlement_date).format('DD/MM/YYYY')}</span>
            },
            {
                key: '1',
                label: 'From',
                children: (
                    <span>
                        <span>
                            {
                                <div
                                    className={`currency-flag currency-flag-${
                                        currency_from_name ? currency_from_name.toLowerCase() : ''
                                    }`}
                                />
                            }
                        </span>{' '}
                        {currency_from_name}{' '}
                        {this.props.intl.formatNumber(this.state.amount_from, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                    </span>
                )
            },
            {
                key: '1',
                label: 'To',
                children: (
                    <span>
                        <span>
                            {
                                <div
                                    className={`currency-flag currency-flag-${
                                        currency_to_name ? currency_to_name.toLowerCase() : ''
                                    }`}
                                />
                            }
                        </span>{' '}
                        {currency_to_name}{' '}
                        {this.props.intl.formatNumber(this.state.amount_to, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                    </span>
                )
            },
            {
                key: '1',
                label: 'Client Rate',
                children: (
                    <span>
                        {this.props.intl.formatNumber(this.state.client_rate, {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4
                        })}
                    </span>
                )
            }
        ];

        if (this.props.app_state.current_staff.backofficeStaff) {
            transferDetails.push({
                key: '1',
                label: 'Bank Rate',
                children: (
                    <span>
                        {this.props.intl.formatNumber(this.state.bank_rate, {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4
                        })}
                    </span>
                )
            });
            transferDetails.push({
                key: '1',
                label: 'Profit',
                children: (
                    <span>
                        {currency_from_name}{' '}
                        {this.props.intl.formatNumber(transferProfit, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                    </span>
                )
            });
        }

        return (
            <React.Fragment>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Descriptions
                            title={`Transfer Details`}
                            layout='horizontal'
                            bordered={true}
                            column={1}
                            size='small'
                            items={transferDetails}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Descriptions
                            title={`Client Details: ${this.state.current_transfer_client.nickname}`}
                            layout='horizontal'
                            bordered={true}
                            column={1}
                            size='small'
                            items={clientDetails}
                        />
                    </GridItem>
                </GridContainer>
                <hr />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Descriptions
                            title={`Remittance Details`}
                            layout='horizontal'
                            bordered={true}
                            column={1}
                            size='small'
                            // items={clientDetails}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Descriptions
                            title={`Payout Details`}
                            layout='horizontal'
                            bordered={true}
                            column={1}
                            size='small'
                            // items={clientDetails}
                        />
                    </GridItem>
                </GridContainer>
                {/* <Button
            color="primary"
            type="submit"
            className={classes.updateProfileButton}
            disabled={!this.state.edit_mode}
          >
            Update Transfer
          </Button> */}
            </React.Fragment>
        );
    }

    ledgerFundsReceived = async () => {
        await API.post('transfers', `/ledger/funds_received/${this.state.id}`, {body: {}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

        API.get('holding_accounts', `/get/client-currency/${this.state.client_id}/${this.state.currency_from_id}`)
            .then(response => {
                console.log(response);
                this.setState({holdingAccountFrom: response.holding_account_list[0]});
            })
            .catch(error => {
                console.log(error);
            });
        await API.get('holding_accounts', `/get/client-currency/${this.state.client_id}/${this.state.currency_to_id}`)
            .then(response => {
                console.log(response);
                this.setState({holdingAccountTo: response.holding_account_list[0]});
            })
            .catch(error => {
                console.log(error);
            });
    };

    ledgerProcessTransfer = async () => {
        await API.post('transfers', `/ledger/process_transfer/${this.state.id}`, {body: {}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

        API.get('holding_accounts', `/get/client-currency/${this.state.client_id}/${this.state.currency_from_id}`)
            .then(response => {
                console.log(response);
                this.setState({holdingAccountFrom: response.holding_account_list[0]});
            })
            .catch(error => {
                console.log(error);
            });
        await API.get('holding_accounts', `/get/client-currency/${this.state.client_id}/${this.state.currency_to_id}`)
            .then(response => {
                console.log(response);
                this.setState({holdingAccountTo: response.holding_account_list[0]});
            })
            .catch(error => {
                console.log(error);
            });
    };

    ledgerFundsPayout = async () => {
        await API.post('transfers', `/ledger/transfer_payout/${this.state.id}`, {body: {}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

        API.get('holding_accounts', `/get/client-currency/${this.state.client_id}/${this.state.currency_from_id}`)
            .then(response => {
                console.log(response);
                this.setState({holdingAccountFrom: response.holding_account_list[0]});
            })
            .catch(error => {
                console.log(error);
            });
        API.get('holding_accounts', `/get/client-currency/${this.state.client_id}/${this.state.currency_to_id}`)
            .then(response => {
                console.log(response);
                this.setState({holdingAccountTo: response.holding_account_list[0]});
            })
            .catch(error => {
                console.log(error);
            });
    };

    getNavPill_Transfer() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card style={{height: '95%'}}>
                                <CardHeader>
                                    <h4>Financials</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                                                    From
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{className: classes.selectMenu}}
                                                    classes={{select: classes.select}}
                                                    value={this.state.currency_from_id || ''}
                                                    onChange={this.handleSelectChange}
                                                    inputProps={{
                                                        disabled: !this.state.edit_mode,
                                                        name: 'currency_base',
                                                        id: 'currency_base'
                                                    }}
                                                >
                                                    <MenuItem
                                                        key='0x0'
                                                        disabled
                                                        classes={{root: classes.selectMenuItem}}
                                                    >
                                                        Select currencies_from to replace {this.state.currency_base}
                                                    </MenuItem>
                                                    {this.state.currencies_list_priority
                                                        //sorted by id
                                                        .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
                                                        .map(item => {
                                                            return (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value={item.id}
                                                                >
                                                                    [{item.iso_alpha_3}] {item.full_name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    {/*<MenuItem*/}
                                                    {/*  key="0x0"*/}
                                                    {/*  value="0"*/}
                                                    {/*  disabled*/}
                                                    {/*  classes={{*/}
                                                    {/*    root: classes.selectMenuItem*/}
                                                    {/*  }}*/}
                                                    {/*>*/}
                                                    {/*  .........................................*/}
                                                    {/*</MenuItem>*/}
                                                    {/*{this.state.currencies_list*/}
                                                    {/*//sorted by id*/}
                                                    {/*  .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))*/}
                                                    {/*  .map((item) => {*/}
                                                    {/*    return (*/}
                                                    {/*      <MenuItem*/}
                                                    {/*        key={item.id}*/}
                                                    {/*        classes={{*/}
                                                    {/*          root: classes.selectMenuItem,*/}
                                                    {/*          selected: classes.selectMenuItemSelected*/}
                                                    {/*        }}*/}
                                                    {/*        value={item.id}*/}
                                                    {/*      >*/}
                                                    {/*        [{item.iso_alpha_3}] {item.full_name}*/}
                                                    {/*      </MenuItem>*/}
                                                    {/*    );*/}
                                                    {/*  })}*/}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                                                    To
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{className: classes.selectMenu}}
                                                    classes={{select: classes.select}}
                                                    value={this.state.currency_to_id || ''}
                                                    onChange={this.handleSelectChange}
                                                    inputProps={{
                                                        disabled: !this.state.edit_mode,
                                                        name: 'currency_terms',
                                                        id: 'currency_terms'
                                                    }}
                                                >
                                                    <MenuItem
                                                        key='0x0'
                                                        disabled
                                                        classes={{root: classes.selectMenuItem}}
                                                    >
                                                        Select currencies_to to replace {this.state.currency_terms}
                                                    </MenuItem>

                                                    {this.state.currencies_list_priority
                                                        //sorted by id
                                                        .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
                                                        .map(item => {
                                                            return (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value={item.id}
                                                                >
                                                                    [{item.iso_alpha_3}] {item.full_name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    {/*<MenuItem*/}
                                                    {/*  key="0x0"*/}
                                                    {/*  value="0"*/}
                                                    {/*  disabled*/}
                                                    {/*  classes={{*/}
                                                    {/*    root: classes.selectMenuItem*/}
                                                    {/*  }}*/}
                                                    {/*>*/}
                                                    {/*  .........................................*/}
                                                    {/*</MenuItem>*/}
                                                    {/*{this.state.currencies_list*/}
                                                    {/*//sorted by id*/}
                                                    {/*  .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))*/}
                                                    {/*  .map((item) => {*/}
                                                    {/*    return (*/}
                                                    {/*      <MenuItem*/}
                                                    {/*        key={item.id}*/}
                                                    {/*        classes={{*/}
                                                    {/*          root: classes.selectMenuItem,*/}
                                                    {/*          selected: classes.selectMenuItemSelected*/}
                                                    {/*        }}*/}
                                                    {/*        value={item.id}*/}
                                                    {/*      >*/}
                                                    {/*        [{item.iso_alpha_3}] {item.full_name}*/}
                                                    {/*      </MenuItem>*/}
                                                    {/*    );*/}
                                                    {/*  })}*/}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Amount from'
                                                id='amount_from'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: !this.state.edit_mode,
                                                    //value: this.state.amount_from || "",
                                                    value: this.state.amount_from,
                                                    onChange: event => {
                                                        this.handleChange(event);
                                                    },
                                                    onBlur: event => this.handleBlur(event)
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Amount to'
                                                id='amount_to'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: !this.state.edit_mode,
                                                    //value: this.state.amount_to || "",
                                                    value: this.state.amount_to,
                                                    onChange: event => {
                                                        this.handleChange(event);
                                                    },
                                                    onBlur: event => this.handleBlur(event)
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Rate'
                                                id='client_rate'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: !this.state.edit_mode,
                                                    // value:
                                                    //   this.state.client_rate ||
                                                    //   "",
                                                    value: this.state.client_rate,
                                                    onChange: event => {
                                                        this.handleChange(event);
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Fee Visible Amount'
                                                id='fee_visible_amount'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: !this.state.edit_mode,
                                                    // value:
                                                    //   this.state.client_rate ||
                                                    //   "",
                                                    value: this.state.fee_visible_amount,
                                                    onChange: event => {
                                                        this.handleChange(event);
                                                    }
                                                }}
                                            />
                                            <CustomInput
                                                labelText='Fee Visible Description'
                                                id='fee_visible_description'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: !this.state.edit_mode,
                                                    // value:
                                                    //   this.state.client_rate ||
                                                    //   "",
                                                    value: this.state.fee_visible_description,
                                                    onChange: event => {
                                                        this.handleChange(event);
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <hr />
                                    <h4>Additional Information Required</h4>
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleToggle('infoRequired')}
                                        checked={this.state.infoRequired == 1 ? true : false}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked}}
                                    />
                                    Click if additional information is required
                                    <br />
                                    <CustomInput
                                        labelText='Information Required Details'
                                        id='infoRequiredNotes'
                                        formControlProps={{fullWidth: true}}
                                        inputProps={{
                                            disabled: !this.state.edit_mode,
                                            value: this.state.infoRequiredNotes || '',
                                            onChange: event => {
                                                this.handleChange(event);
                                            }
                                        }}
                                    />
                                    <br />
                                    <ButtonAntD type='primary'>Send Information Request Email</ButtonAntD>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card style={{height: '95%'}}>
                                <CardHeader>
                                    <h4>Transfer Details</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Transfer ID'
                                                id='id'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.id || '',
                                                    onChange: event => {
                                                        this.handleChange(event);
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Description'
                                                id='nickname'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: !this.state.edit_mode,
                                                    value: this.state.nickname || '',
                                                    onChange: event => {
                                                        this.handleChange(event);
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                                                    Status
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{className: classes.selectMenu}}
                                                    classes={{select: classes.select}}
                                                    value={this.state.status || ''}
                                                    onChange={this.handleSelectChange}
                                                    inputProps={{
                                                        disabled: !this.state.edit_mode,
                                                        name: 'status',
                                                        id: 'status'
                                                    }}
                                                >
                                                    <MenuItem
                                                        key='0x0'
                                                        disabled
                                                        classes={{root: classes.selectMenuItem}}
                                                    >
                                                        Select client {' from ' + this.state.clients_fullname}
                                                    </MenuItem>
                                                    {this.state.transfer_status_list
                                                        // .sort((a, b) => a.nickname.localeCompare(b.nickname))
                                                        .map(item => {
                                                            return (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value={item.id}
                                                                >
                                                                    {item.nickname}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>

                                    <hr />
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            {this.state.holdingAccountFrom.currencies_short_name}:{' '}
                                            {this.state.holdingAccountFrom.balance
                                                ? this.state.holdingAccountFrom.balance.toFixed(2)
                                                : ''}
                                            <br />
                                            {this.state.holdingAccountTo.currencies_short_name}:{' '}
                                            {this.state.holdingAccountTo.balance
                                                ? this.state.holdingAccountTo.balance.toFixed(2)
                                                : ''}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <ButtonAntD
                                                type='primary'
                                                disabled={!this.state.edit_mode}
                                                onClick={() => this.ledgerFundsReceived()}
                                            >
                                                Ledger Receive
                                            </ButtonAntD>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <span>
                                                {this.state.status_fundsReceivedDatetime &&
                                                    this.state.status_fundsReceivedDatetime !== '' &&
                                                    moment(this.state.status_fundsReceivedDatetime).format(
                                                        'DD/MM/YYYY HH:mm'
                                                    )}
                                            </span>
                                        </GridItem>
                                    </GridContainer>
                                    <br />
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <ButtonAntD
                                                type='primary'
                                                disabled={!this.state.edit_mode}
                                                onClick={() => this.ledgerProcessTransfer()}
                                            >
                                                Ledger Convert
                                            </ButtonAntD>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <span>
                                                {this.state.status_ledgerTransferDatetime &&
                                                    this.state.status_ledgerTransferDatetime !== '' &&
                                                    moment(this.state.status_ledgerTransferDatetime).format(
                                                        'DD/MM/YYYY HH:mm'
                                                    )}
                                            </span>
                                        </GridItem>
                                    </GridContainer>
                                    <br />
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <ButtonAntD
                                                type='primary'
                                                disabled={!this.state.edit_mode}
                                                onClick={() => this.ledgerFundsPayout()}
                                            >
                                                Ledger Payout
                                            </ButtonAntD>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <span>
                                                {this.state.status_fundsPayoutDatetime &&
                                                    this.state.status_fundsPayoutDatetime !== '' &&
                                                    moment(this.state.status_fundsPayoutDatetime).format(
                                                        'DD/MM/YYYY HH:mm'
                                                    )}
                                            </span>
                                        </GridItem>
                                    </GridContainer>
                                    <hr />
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            Transaction
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    inputProps={{
                                                        disabled: !this.state.edit_mode,
                                                        // placeholder: this.getFullDate(new Date(this.state.transaction_datetime))
                                                        placeholder: this.state.transaction_datetime
                                                            ? moment(this.state.transaction_datetime).format(
                                                                  'DD/MM/YYYY'
                                                              )
                                                            : '',
                                                        value: this.state.transaction_datetime
                                                            ? moment(this.state.transaction_datetime).format(
                                                                  'DD/MM/YYYY'
                                                              )
                                                            : ''
                                                        //value: this.getFullDate(new Date(this.state.transaction_datetime)),
                                                    }}
                                                    onChange={event => {
                                                        if (event.isValid !== undefined) {
                                                            const selDate = this.getFullDateTime(
                                                                new Date(event.toDate())
                                                            );
                                                            this.setState({transaction_datetime: selDate});
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            Settlement
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    inputProps={{
                                                        disabled: !this.state.edit_mode,
                                                        // placeholder: this.getFullDate(new Date(this.state.settlement_date))
                                                        placeholder: this.state.settlement_date
                                                            ? moment(this.state.settlement_date).format('DD/MM/YYYY')
                                                            : '',
                                                        value: this.state.settlement_date
                                                            ? moment(this.state.settlement_date).format('DD/MM/YYYY')
                                                            : ''

                                                        //value: this.getFullDate(new Date(this.state.transaction_datetime)),
                                                    }}
                                                    onChange={event => {
                                                        if (event.isValid !== undefined) {
                                                            const selDate = this.getFullDateTime(
                                                                new Date(event.toDate())
                                                            );
                                                            this.setState({settlement_date: selDate});
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            Payout
                                            <br />
                                            <FormControl fullWidth>
                                                <Datetime
                                                    inputProps={{
                                                        disabled: !this.state.edit_mode,
                                                        // placeholder: this.getFullDate(new Date(this.state.payout_datetime))
                                                        placeholder: this.state.payout_datetime
                                                            ? moment(this.state.payout_datetime).format(
                                                                  'DD/MM/YYYY hh:mm:ss A'
                                                              )
                                                            : '',
                                                        value: this.state.payout_datetime
                                                            ? moment(this.state.payout_datetime).format(
                                                                  'DD/MM/YYYY hh:mm:ss A'
                                                              )
                                                            : ''
                                                        //value: this.getFullDate(new Date(this.state.payout_datetime)),
                                                    }}
                                                    onChange={event => {
                                                        if (event.isValid !== undefined) {
                                                            const selDate = this.getFullDateTime(
                                                                new Date(event.toDate())
                                                            );
                                                            this.setState({payout_datetime: selDate});
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Payout Bank Reference'
                                                id='payout_bank_reference'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: !this.state.edit_mode,
                                                    value: this.state.payout_bank_reference || '',
                                                    onChange: event => {
                                                        this.handleChange(event);
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={6}>
            </GridItem> */}
                    </GridContainer>
                    <ButtonAntD
                        type='primary'
                        disabled={!this.state.edit_mode}
                        loading={this.state.buttonLoading}
                        className={classes.updateProfileButton}
                        onClick={() => this.handleSubmit2()}
                    >
                        Update Transfer
                    </ButtonAntD>
                    {/* <Button
            color='primary'
            type='submit'
            className={classes.updateProfileButton}
            disabled={!this.state.edit_mode}
          >
            Update Transfer
          </Button> */}
                </form>
            </React.Fragment>
        );
    }

    getNavPill_Client() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <HorizontalStepperNew transfer_status={this.state.status} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card style={{height: '90%'}}>
                                <CardHeader>
                                    <h6>Client Details</h6>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                                                    Client
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{className: classes.selectMenu}}
                                                    classes={{select: classes.select}}
                                                    value={this.state.client_id || ''}
                                                    onChange={this.handleSelectChange}
                                                    inputProps={{
                                                        disabled: !this.state.edit_mode,
                                                        name: 'client_id',
                                                        id: 'client_id'
                                                    }}
                                                >
                                                    <MenuItem
                                                        key='0x0'
                                                        disabled
                                                        classes={{root: classes.selectMenuItem}}
                                                    >
                                                        Select client {' from ' + this.state.clients_fullname}
                                                    </MenuItem>
                                                    {this.state.client_list
                                                        .sort((a, b) => a.last_name.localeCompare(b.last_name))
                                                        .map(item => {
                                                            return (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    value={item.id}
                                                                >
                                                                    {item.nickname + ' (' + item.email + ')'}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                    <hr />
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Nickname'
                                                id='current_transfer_client.nickname'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.current_transfer_client.nickname || ''
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='First Name'
                                                id='current_transfer_client.first_name'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.current_transfer_client.first_name || ''
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Middle Name'
                                                id='current_transfer_client.middle_name'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.current_transfer_client.middle_name || ''
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText='Last Name'
                                                id='current_transfer_client.last_name'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.current_transfer_client.last_name || ''
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText='Email'
                                                id='current_transfer_client.email'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.current_transfer_client.email || ''
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText='Team'
                                                id='current_transfer_client.team_nickname'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.current_transfer_client.team_nickname || ''
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText='Division'
                                                id='current_transfer_client.division_nickname'
                                                formControlProps={{fullWidth: true}}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.current_transfer_client.division_nickname || ''
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div
                                                style={{
                                                    color: '#AAAAAA',
                                                    fontSize: '11px',
                                                    marginTop: '10px'
                                                }}
                                            >
                                                Client ID
                                            </div>
                                            <a
                                                href={`/clients/edit/${this.state.current_transfer_client.id}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                {this.state.current_transfer_client.id}
                                            </a>
                                            <hr
                                                style={{
                                                    borderTop: '1px dotted lightgray',
                                                    marginTop: '7px'
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <Button
                        color='primary'
                        type='submit'
                        className={classes.updateProfileButton}
                        disabled={!this.state.edit_mode}
                    >
                        Update Transfers
                    </Button>
                </form>
            </React.Fragment>
        );
    }

    getActive = () => {
        const query_strings = queryString.parse(this.props.location.search);
        if (query_strings) {
            switch (query_strings.tab) {
                case 'summary':
                    this.setState({navpill_active: 0});
                    break;
                case 'transfer':
                    this.setState({navpill_active: 1});
                    break;
                // case 'ledger':
                //   this.setState({navpill_active: 2});
                //   break;
                // case 'invoice':
                //   this.setState({navpill_active: 3});
                //   break;
                // case 'client':
                //   this.setState({navpill_active: 3});
                //   break;
                case 'remittance':
                    this.setState({navpill_active: 2});
                    break;
                case 'payout':
                    this.setState({navpill_active: 3});
                    break;
                // case 'splitpay':
                //   this.setState({navpill_active: 7});
                //   break;
                case 'documents':
                    this.setState({navpill_active: 4});
                    break;
                default:
                    this.setState({navpill_active: 0});
                    break;
            }
            // const search = this.props.location.search;
            // if (search.split('=')[0] == '?tab') {
            //   if (search.split('=')[1] == 'payouts') {
            //     return 5;
            //   }
        }
        // return 0;
    };

    render() {
        const {classes} = this.props;

        if (!this.state.currency_to_id) {
            return null;
        }
        if (!this.state.loaded) {
            return null;
        }
        if (!this.props.app_state.current_staff) {
            return null;
        }

        console.log(this.state);

        let steps = [
            {
                tabButton: 'Summary',
                tabContent: <CardBody>{this.getNavPill_Summary()}</CardBody>
            },
            {
                tabButton: 'Transfer',
                tabContent: <CardBody>{this.getNavPill_Transfer()}</CardBody>
            },
            // {
            //   tabButton: 'Ledger',
            //   tabContent: (
            //     <TransferDetailLadger
            //       {...this.props}
            //       currencies_list_priority={this.state.currencies_list_priority}
            //       currency_from_id={this.state.currency_from_id}
            //       currency_to_id={this.state.currency_to_id}
            //       holdingAccountFrom={this.state.holdingAccountFrom}
            //       state={this.state}
            //       client_id={this.state.client_id}
            //     />
            //   )
            // },
            // {
            //   tabButton: 'Invoice',
            //   tabContent: (
            //     <React.Fragment>
            //       {this.state.transfers_pending_id && (
            //         <TransferDetailInvoice transfers_pending_id={this.state.transfers_pending_id} />
            //       )}
            //     </React.Fragment>
            //   )
            // },
            // {
            //   tabButton: 'Client',
            //   tabContent: <CardBody>{this.getNavPill_Client()}</CardBody>
            // },
            {
                tabButton: 'Remittance',
                tabContent: (
                    <TransferDetailRemittance
                        edit_mode={this.state.edit_mode}
                        transfer_id={this.props.transfer_detail_id}
                        remitting_bank_id={this.state.remitting_bank_account_id}
                        currency_from_id={this.state.currency_from_id}
                        amount_from={this.state.amount_from}
                        rate={this.state.client_rate}
                        settlementDate={dayjs(this.state.settlement_date).format(
                            'DD/MM/YYYY'
                        )}
                        currency_to_id={this.state.currency_to_id}
                        amount_to={this.state.amount_to}
                        from_balance={this.state.from_balance}
                        client_id={this.state.client_id}
                        current_staff_super_admin={
                            this.props.app_state.current_staff_super_admin
                        }
                        received_datetime={this.state.received_datetime}
                        received_notes={this.state.received_notes}
                        language_id={this.state.current_transfer_client.language_id}
                        transfer_status={this.state.status}
                        ledgerFrom={this.state.ledgerFrom}
                        transfer_status2={this.state.transfer_status}
                        fee_visible_amount={this.state.fee_visible_amount}
                        fee_visible_description={this.state.fee_visible_description}
                    />
                )
            },
            {
                tabButton: 'Payout',
                tabContent: this.state.clientHoldingAccountId ? (
                    <>TRANSFER TO CLIENT HOLDING ACCOUNT LEDGER</>
                ) : (
                    <TransferDetailPayout
                        edit_mode={this.state.edit_mode}
                        transfer_id={this.props.transfer_detail_id}
                        transfer_nickname={this.state.nickname}
                        beneficiary_id={this.state.beneficiary_id}
                        currency_from_id={this.state.currency_from_id}
                        currency_to_id={this.state.currency_to_id}
                        amount_from={this.state.amount_from}
                        amount_to={this.state.amount_to}
                        rate={this.state.client_rate}
                        settlement_date={this.state.settlement_date}
                        client_id={this.state.client_id}
                        payout_datetime={this.state.payout_datetime}
                        payout_bank_reference={this.state.payout_bank_reference}
                        current_staff_super_admin={
                            this.props.app_state.current_staff_super_admin
                        }
                        language_id={this.state.current_transfer_client.language_id}
                        transfer_status={this.state.status}
                        transfer_status2={this.state.transfer_status}
                    />
                )
            },
            // {
            //   tabButton: 'SplitPay',
            //   tabContent: (
            //     <Fragment>
            //       {this.state.splitPay === 1 ? (
            //         <TransferDetailSplitPayPayout
            //           edit_mode={this.state.edit_mode}
            //           transfer_id={this.props.transfer_detail_id}
            //           transfer_nickname={this.state.nickname}
            //           beneficiary_id={this.state.beneficiary_id}
            //           currency_from_id={this.state.currency_from_id}
            //           currency_to_id={this.state.currency_to_id}
            //           amount_from={this.state.amount_from}
            //           amount_to={this.state.amount_to}
            //           rate={this.state.client_rate}
            //           settlement_date={this.state.settlement_date}
            //           client_id={this.state.client_id}
            //           payout_datetime={this.state.payout_datetime}
            //           payout_bank_reference={this.state.payout_bank_reference}
            //           current_staff_super_admin={this.props.app_state.current_staff_super_admin}
            //           language_id={this.state.current_transfer_client.language_id}
            //           transfer_status={this.state.status}
            //         />
            //       ) : (
            //         <div>No Split Payment</div>
            //       )}
            //     </Fragment>
            //   )
            // },
            {
                tabButton: 'Documents',
                tabContent: (
                    <Fragment>
                        <TransferDocuments
                            transferId={this.state.id}
                            history={this.props.history}
                        />
                    </Fragment>
                )
            }
        ];

        const restrictedTabs = ['Transfer', 'Remittance', 'Payout'];
        if (
            this.props.app_state.current_staff.backofficeStaff === 0 ||
            this.props.app_state.current_staff.sales_staff === 0 ||
            this.props.app_state.current_staff.referrer === 0
        ) {
            steps = steps.filter(i => !restrictedTabs.includes(i.tabButton));
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <AntCard title={this.getPageHeader(classes)}>
                            <>
                                <NavPills
                                    active={this.state.navpill_active}
                                    color='info'
                                    tabs={steps}
                                />
                                <Clearfix />
                            </>
                        </AntCard>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

TransferEdit.propTypes = {classes: PropTypes.object.isRequired};

// export default injectIntl(withStyles(staffEditStyles)(TransferEdit));

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        clients: state.clients
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // loadClientDetailsRequest: () => {
        //   dispatch(loadClientDetailsRequest())
        // },
        // loadClientDetailsSuccess: () => {
        //   dispatch(loadClientDetailsSuccess())
        // },
        loadTransferDetailsRequest: () => {
            dispatch(loadTransferDetailsRequest());
        },
        loadTransferDetailsSuccess: () => {
            dispatch(loadTransferDetailsSuccess());
        }
    };
};

const TransferEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(withStyles(staffEditStyles)(TransferEdit)));

export default TransferEditContainer;
