import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Flex, Form, message, Modal, Spin, Switch, Table} from 'antd';
import {TransferDetailAPI} from './TransferDetailConstants';
import TextArea from 'antd/lib/input/TextArea';
import listTable from "../../ListTable/ListTable";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import FileUploader from "../../Uploaders/FileUploader";
import {API} from "aws-amplify";

const DocumentsListContainer = props => {
    const {transferId, history} = props;
    const uploadRef = useRef();
    const multiUploadRef = useRef();
    const [transferDocumentsList, setTransferDocumentsList] = useState([]);
    const [uploadedFileIds, setUploadedFileIds] = useState([]);
    const [componentState, setComponentState] = useState({
        isModalOpen: false,
        description: '',
        isLoading: false,
        isPublic: false
    });

    const onModalCancel = () => {
        setComponentState(prev => ({
            ...prev,
            isModalOpen: false,
            description: null
        }));
        if (multiUploadRef.current && multiUploadRef.current.clearList != null) {
            multiUploadRef.current.clearList();
        }
    }

    const onUpdate = (file) => {
        setUploadedFileIds(prev => {
            prev.push(file.name);
            return prev;
        })
    }

    const onRemove = (file) => {
        const files = uploadedFileIds.filter(i => i !== file.name);
        setUploadedFileIds(files);
    }

    const setDocumentVisibility = (record, data) => {
        console.log(record, data);
        const loading = message.loading("Updating transfer document. Please wait..", 0);
        API.post("commons", `/update/${record.id}`, {
            body: {
                context: 'transferDocuments',
                data: {visiblePublic: data}
            }
        }).then(() => {
            message.success(`Document #${record.id} public status changed`);
            fetchDocumentsByTransferId(transferId);
        }).catch(err => {
            message.error(`Document #${record.id} public status changing failed`);
        }).finally(() => loading());
    }

    const buildColumns = () => {
        return [
            {
                key: 'id',
                title: 'ID',
                dataIndex: 'id',
                sorter: (a,b) => a.id - b.id,
                defaultSortOrder: 'descend'
            },
            {
                key: 'transferId',
                title: 'Transfer ID',
                dataIndex: 'transferId'
            },
            {
                key: 'documentId',
                title: 'Document ID',
                dataIndex: 'documentId',
                ...props.getColumnSearchProps({
                    dataIndex: 'documentId',
                    render: (text, record) => {
                        let ids = [];
                        const data = record.attachments;
                        if (data) {
                            const parsed = JSON.parse(data);
                            if (parsed.ids && parsed.ids.length > 0) {
                                ids = parsed.ids;
                            }
                        } else {
                            ids = [text];
                        }
                        return ids.join(', ')
                    }
                })
            },
            {
                key: 'description',
                title: 'Description',
                ellipsis: true,
                dataIndex: 'description'
            },
            {
                key: 'recordCreated',
                title: 'Record Created',
                ellipsis: true,
                dataIndex: 'recordCreated',
                render: (text, record) => {
                    return text == null ? '' : dayjs(text).format('DD/MM/YYYY');
                }
            },
            {
                key: 'visiblePublic',
                title: 'Public',
                ellipsis: true,
                dataIndex: 'visiblePublic',
                render: (text, record) => <Switch defaultChecked={text === 1} onClick={(e) => setDocumentVisibility(record, e)}/>
            },
            {
                key: 'attachments',
                title: 'Action',
                ellipsis: true,
                dataIndex: 'attachments',
                render: (text, record) => {
                    return <Flex justify={'start'} gap={5}>
                        <Button type={'primary'}
                                onClick={() => props.history.push(`/transfers/documents/view/${record.id}`)}
                                icon={<EyeOutlined/>}></Button>
                        <Button type={'primary'}
                                danger={true}
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Are you sure ?',
                                        content: `Are you sure you want to delete the document #${record.id}`,
                                        onOk: () => {
                                            const loading = message.loading(`Deleting document id ${record.id}. Please wait..`, 0);
                                            API.post("commons", `/update/${record.id}`, {
                                                body: {
                                                    context: 'transferDocuments',
                                                    data: {deleted: true}
                                                }
                                            }).then(() => {
                                                message.success("Document has been deleted");
                                                fetchDocumentsByTransferId(record.transferId);
                                                API.post("commons", "/fetch", {
                                                    body: {
                                                        context: 'transferDocuments',
                                                        fields: ['*'],
                                                        condition: {transferId: transferId, deleted: false}
                                                    }
                                                }).then(res => {
                                                    if (res.length === 0) {
                                                        API.post("commons", "/backend-update", {
                                                            body: {
                                                                context: 'transfer_req',
                                                                data: {has_document: 0},
                                                                condition: {tran_crm_id: transferId, has_document: 1}
                                                            }
                                                        }).then(() => {
                                                            console.log("success");
                                                        }).catch((err) => {
                                                            console.log(err);
                                                        })
                                                    }
                                                })
                                            }).catch(err => {
                                                console.log(err);
                                                message.error("Failed to delete the document. Please try again");
                                            }).finally(() => loading());
                                        }
                                    })
                                }}
                                icon={<DeleteOutlined />}></Button>
                    </Flex>
                }
            },
        ]
    }

    const saveTransferDocument = () => {
        const transferDocumentData = {
            transferId,
            description: componentState.description,
            visiblePublic: componentState.isPublic,
            file_refs: uploadedFileIds
        };
        setComponentState(prev => ({...prev, isLoading: true}))
        TransferDetailAPI.saveTransferDocument(transferDocumentData)
            .then(() => {
                setComponentState(prev => ({...prev, isModalOpen: false}))
            }).catch(error => {
            TransferDetailAPI.saveTransferDocument(transferDocumentData)
                .then(() => setComponentState(prev => ({...prev, isModalOpen: false})))
                .catch(error => {
                    alert('Upload Failed');
                });
        }).finally(() => {
            setComponentState(prev => ({
                ...prev,
                isLoading: false,
                description: null,
            }))
            fetchDocumentsByTransferId(transferId);
            uploadRef.current.clearList();
        });
    }

    const fetchDocumentsByTransferId = (transferId) => {
        TransferDetailAPI.fetchTransferDocuments(transferId).then(response => {
            setTransferDocumentsList(response);
        })
            .catch(error => {
                console.log(error);
                TransferDetailAPI.fetchTransferDocuments(transferId)
                    .then(response => {
                        setTransferDocumentsList(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
    };

    useEffect(() => {
        if (transferId) {
            fetchDocumentsByTransferId(transferId);
        }

    }, [transferId]);

    return (
        <>
            <Modal title={`Upload Transfer Documents to transfer id - ${transferId}`}
                   open={componentState.isModalOpen}
                   onOk={() => saveTransferDocument()}
                   onCancel={onModalCancel}>
                <Form.Item labelAlign={'left'} labelCol={{ span: 6 }} label={'Description'}>
                    <TextArea value={componentState.description} placeholder={'Enter Invoice Description'}
                              name={'description'}
                              style={{marginBottom: '10px'}}
                              onChange={(e) => setComponentState(prev => ({...prev, description: e.target.value}))}/>
                </Form.Item>
                <Form.Item labelAlign={'left'} labelCol={{ span: 6 }} label={'Public ?'}>
                    <Checkbox defaultChecked={componentState.isPublic} onChange={(e) => {
                        setComponentState(prev => ({...prev, isPublic: e.target.checked }));
                    }}></Checkbox>
                </Form.Item>

                <Form.Item labelAlign={'left'} labelCol={{ span: 6 }} label={'Upload Image'}>
                    <FileUploader uploaderType={'DRAGDROP'}
                                  onDelete={(file) => {
                                      onRemove(file);
                                      return Promise.resolve();
                                  }}
                                  thumbType={'picture-card'}
                                  ref={uploadRef}
                                  onUploadSuccess={file => {
                                      onUpdate(file);
                                  }}></FileUploader>
                </Form.Item>
                <span style={{textAlign: 'center', display: componentState.isLoading ? 'block' : 'none'}}><Spin/> Saving... Please wait</span>
            </Modal>
            <Flex justify={'end'} gap={5}>
                <Button onClick={() => setComponentState(prev => ({...prev, isModalOpen: true}))}
                        type={'primary'}><span>Upload Document <i
                    className={'fas fa-cloud-upload-alt'}></i></span></Button>
            </Flex>
            <Table columns={buildColumns()} dataSource={transferDocumentsList} loading={componentState.isLoading}></Table>
        </>
    );
};

export default listTable(DocumentsListContainer);
